// Here you can add other styles
// Here you can add other styles
.loading {
	position: fixed;
	left: 50%;
	right: 50%;
	top: 35%;
}
.inline-dd {
	display: inline;
	padding: 5px;
}
.inline-dd button {
	background-color: RGBA(0,0,0,0);
}
.no-decoration:hover {
	text-decoration: none;
}
.col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
	padding-top: 15px;
}

.mce-fullscreen {
	z-index: 1050;
}

*[role="button"], *[role="link"] {
	cursor: pointer;
	padding: 0.3rem;
	margin-right: 0.3rem;
	color: inherit;
}
.leaflet-control-zoom [role="button"] {
	margin-right: 0;
}
*[role="button"]:hover {
	background: linear-gradient(to left top, lightgray, #f0f3f5);
}
*[role="link"]:hover {
	color: #20a8d8;
	text-decoration: none;
}

.react-autosuggest__container {
	position: relative;
}

.react-autosuggest__input--focused {
	outline: none;
}

.no-outline-on-focus:focus {
	outline: none;
	border-color: #e4e7ea;
	box-shadow: none;
}

.react-autosuggest__input--open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__suggestions-container--open {
	display: block;
	position: absolute;
	width: 100%;
	border: 1px solid #aaa;
	background-color: #fff;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	z-index: 2;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #ddd;
}

.react-autosuggest__section-container {
	border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
	border-top: 0;
}

.react-autosuggest__section-title {
	padding: 10px 0 0 10px;
	font-size: 12px;
	color: #777;
}

.semi-transparent {
	opacity: 0.5 !important;
}
.no-wrap {
	white-space: nowrap;
}
.btn-xsm {
	padding: 0 0.1rem;
}

.row-selectable:hover {
	cursor: pointer;
	background-color: rgba(239,237,218,0.6) !important;
}

.row-selected {
	background-color: #efedda !important;
}

.alert .time {
	color: gray;
	font-size: x-small;
	float: right;
}

.warning {
	color: orange;
	font-size: x-small;
}

.error {
	color: red;
	font-size: x-small;
}

.login-nav .dropdown button {
	background-color: inherit;
	border-width: 0;
	color: gray;
}

.alert-container {
	position: fixed;
	z-index: 10000;
	right: 5rem;
	pointer-events: none;
	opacity: 0.85;
}

.alert {
	border-radius: 8px;
	margin-bottom: 0.5rem;
	pointer-events: none;
}

.modal-header {
	background-color: #cfcfcf;
}

.application-label {
	background-color: #ccc;
	color: #0047f9;
	width: 100%;
	padding: 4px 10px;
}

.filepond--root {
	margin-bottom: 0;
}

/*.aside-menu {
	background-color: #d5d5d5;
}*/

.scroller {
	position: absolute;
	overflow-y: auto;
}

.aside-menu .nav-link.active {
	background-color: #f0f3f5 !important;
}

/*.tab-content {
	background-color: #29363d !important;
}*/

.tab-pane {
	padding: 1rem !important;
}

.w-auto {
	width: auto;
}

.w-fit-content {
	width: fit-content;
}

.min-w-25 {
	min-width: 25%;
}

.min-w-50 {
	min-width: 50%;
}

.min-w-75 {
	min-width: 75%;
}

.min-w-100 {
	min-width: 100%;
}

.text-inherit {
	font-size: inherit;
}

.search-results {
	font-family: arial,sans-serif;
	font-size: small;
}

.shadow-sm {
	box-shadow: 3px 3px 12px gray;
}

#draw_frame {
	width: 100%;
	height: 75vh;
	overflow: hidden;
}

.focus-no, .focus-no:focus {
	outline-style: none !important;
	box-shadow: none;
}

.mw-786 {
	max-width: 786px;
}

.on-minimized {
	display: none;
}

.sidebar .sidebar-nav {
	flex: 1 1 auto !important;
}

.sidebar-minimized {
	.on-maximized {
		display: none;
	}

	i.on-minimized {
	  display: block;
	  float: left;
	  width: $sidebar-minimized-height;
	  font-size: 18px;
	  margin: 0 ($sidebar-nav-link-padding-x / 2) 0 0;
	  font-size: 14px;
	  color: $sidebar-nav-link-icon-color;
	  text-align: center;
	}
}

.aside-menu-resizer {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 0;
	cursor: w-resize;
}

.aside-menu-show .aside-menu-resizer, .aside-menu-lg-show .aside-menu-resizer {
	width: 8px;
}

.with-border-bottom {
	border-bottom: #f0f3f5 1px solid;
}

.fixed-height {
	min-height: 37px;
}

.bg-light.with-border-bottom {
	border-bottom: white 1px solid;
}

.modal-xl {
	max-width: 85%;
}
.modal-l {
	max-width: 70%;
}

.cursor-pointer {
	cursor: pointer;
}

.floating-label {
	position: absolute;
	opacity: 0;
	line-height: 1.3em;
	z-index: 1;
	display: block;
	font-size: 10;
	font-weight: bold;
	top: 0;
	color: grey;
	transform: none;
	box-sizing: border-box;
	user-select: none;
	padding: 0 0.2em;
	background-color: #ffffff;
	pointer-events: none;
	text-overflow: ellipsis;
	overflow: hidden;
	width: auto;
	margin: 0.175em 1em;
}

.i-circle {
	background: #3465a4;
	color: #fff;
	padding: 5px 7px;
	border-radius: 50%;
	font-weight: 800;
}

.react-cookie-law-dialog {
	bottom: 0 !important;
	top: auto !important;
	background-color: rgba(90, 90, 90, 0.9) !important;
}

@media (min-width: 992px) {
	.modal-xl {
		max-width: 800px;
	}
	.modal-l {
		max-width: 600px;
	}
}
@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1100px;
	}
	.modal-l {
		max-width: 800px;
	}
}
@media (min-width: 1400px) {
	.modal-xl {
		max-width: 1300px;
	}
	.modal-l {
		max-width: 800px;
	}
}

.air-quality-index-div{
    height: 150px;
    width: 150px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    border-radius: 20px;
    align-items: center;
    display: table-cell;
    vertical-align: middle;
    border: 1px solid lightgray;
}

.air-quality-index-div-title{
    font-size: larger;
    font-weight: bold;
}

.air-quality-index-div-timestamp{

}

.air-quality-index-div-value{
    font-size: xx-large;
    font-weight: bolder;
}
.air-quality-index-0{
    background-color: #a0a0a0;
    opacity:0.5;
    margin: 10px;
}
.air-quality-index-1{
    background-color: #5ED5FF;
    margin: 10px;
}
.air-quality-index-2{
    background-color: #92D14F;
    margin: 10px;
}
.air-quality-index-3{
    background-color: #FFFF00;
    margin: 10px;
}
.air-quality-index-4{
    background-color: #FC3903;
    margin: 10px;
    color: white;
}
.air-quality-index-5{
    background-color: #990100;
    margin: 10px;
    color: white;
}
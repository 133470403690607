.thermiair-dashboard-class-1 {
    background-color: #5ED5FF;
    font-weight: bold;
    height: 50px;
}

.thermiair-dashboard-class-2 {
    background-color: #92D14F;
    font-weight: bold;
    height: 50px;
}

.thermiair-dashboard-class-3 {
    background-color: #FFFF00;
    font-weight: bold;
    height: 50px;
}

.thermiair-dashboard-class-4 {
    background-color: #FC3903;
    color: white;
    font-weight: bold;
    height: 50px;
}

.thermiair-dashboard-class-5 {
    background-color: #990100;
    color: white;
    font-weight: bold;
    height: 50px;
}

.thermiair-dashboard-div {
    margin: 40px
}

.map {
    height: calc(100% - 9em);
    position: absolute;
    left: 0;
    right: 0;
    top: 6em;

}

.thermiair-dashboard-div a:link {
    color: #000000;
    text-decoration: none
}

.thermiair-dashboard-div a:visited {
    color: #000000;
    text-decoration: none
}

.thermiair-dashboard-div a:hover { /*color: #3366CC*/;
    text-decoration: none
}

.thermiair-dashboard-div a:active {
    color: #000000;
    text-decoration: none
}

.ol-control.ol-layerswitcher-popup {
    top: 0.5em;
    font-size: smaller;
}

.ol-control.ol-layerswitcher-popup.ol-collapsed .panel {
    display: block;
}

.ol-control.ol-layerswitcher-popup.ol-collapsed .panel > li:nth-child(2) {
    display: none;
}

.ol-control.ol-layerswitcher-popup .panel > li:nth-child(2) {
    display: none;
}

.ol-control.ol-layerswitcher-popup button {
    display: none;
}

#currentconditions{
    position: relative;
}

#ol-stationInfoDiv-close {
    position: absolute;
    cursor: pointer;
    top: -5px;
    right: 0;
    padding: 5px;
    font-size: x-large;
}

.dygraph-div {
    width: 100%;
    height: 320px;
    margin-bottom: 40px;
}

#container {
    position: relative;
    width: 60%;
    height: 80%;
}

.dygraphChart{
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    min-width: 100%;
    max-height: 100%;
}

.dygraph-title {
    z-index: 10;
    font-size: small;
    text-align: center;
}

#darkbg .dygraph-axis-label {
    color: white;
}

.dygraph-legend {
    text-align: right;
    font-size: small;
    padding-top: 20px;
}

#darkbg .dygraph-legend {
    background-color: #101015;
}

#chart-week-container{
    position: relative;
    height: 260px;
}

#chart-week{
    position: absolute;
    left: -30px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.dygraph-axis-label-y{
    font-size: x-small;
}

.dygraph-axis-label-x{
    font-size: x-small;
}

#chart-week img{
    min-height: 10%;
}

.ol-overlay > div {
    width: 100%;
    height: 100%;
}

.ol-overlay img {
    margin: auto;
    display: block;
    min-height: 80%;
    max-width: 100%;
    max-height: 80%;
}

.ol-overlay p {
    margin: 0;
    text-align: center;
}

.ol-overlay p.copy {
    font-size: 0.8em;
    text-align: initial;
}


.current-conditions {
    line-height: 1.3em;
    max-width: 460px;
    padding: 10px;
    border-width: 0;
    border-color: #fff;
    border-radius: 10px;
    margin: auto;
    width: 50%;
}

.popup-time-stamp {
    padding: 3px 0 3px;
    white-space: nowrap;
}

.popup-conditions {
    font-size: 16px;
    font-weight: 700;
    float: left;
    width: 50%;
}

.popup-aqi {
    font-weight: 700;
    font-size: 68px;
    float: right;
    width: 50%;
    padding-top: .2em;
    text-align: center;
    margin-bottom: .5em;
}

.popup-history {
    margin-top: 4.5em;
    font-size: initial;
    text-align: center;
}

.legend-tooltip-popup {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    font-size: x-small;
    width: 15%;
    border-style: solid;
    border-width: 0.5px;
}

.popup-more {
    float: right;
}

.pollution-variation-div {
    border-radius: 10px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.ol-airqualitylegend-info {
    display: none;
    bottom: 1em;
    right: .5em;
    /*background-color: white;*/
    /*width: 15em;*/
    padding: 0.5em;
    font-size: smaller;
}

.ol-airqualitylegend-info-show{
    display: block;
}

.ol-airqualitylegend-info img{
    width: 20em;
}

@media only screen and (max-width: 600px) {
    .ol-airqualitylegend-info {
        display: none;
    }
    .ol-airqualitylegend-info-show{
        display: none;
    }
}
